<script setup>
import { computed } from 'vue';

const emit = defineEmits(['updateStepCounter']);

const props = defineProps({
    activeStepId: {
        type: Number,
        required: true
    },
    steps: {
        type: Array, // Change the type to Array for props.steps
        required: true
    },
    isSingle: {
        type: Boolean, 
        required: true
    }
});

const selectStep = (stepIndex) => {
    // Emit your event here if needed
    // emit('updateStepCounter', stepIndex)
}

// Define stepClasses as a computed property
const stepClasses = computed(() => {
    return props.steps.map((step, stepIndex) => {
        if (props.isSingle) {
            return {
                'done': stepIndex <= props.activeStepId - 3,
                'active': stepIndex === props.activeStepId - 2,
            };
        } else {
            return {
                'done': stepIndex <= props.activeStepId - 2,
                'active': stepIndex === props.activeStepId - 1,
            };
        }
    });
});
</script>

<template>
    <div class="vue-steps-counter">
        <div class="steps-wrapper">
            <div class="steps-container">
                <div class="steps-list">
                    <!-- Iterate over props.steps using step and stepIndex -->
                    <div @click="selectStep(stepIndex + 1)" v-for="(step, stepIndex) in props.steps" :key="stepIndex" class="step" :class="stepClasses[stepIndex]">
                        <div class="step-row upper">
                            <div class="step-count">
                                <p>{{ stepIndex + 1 }} van {{ props.steps.length }}</p>
                            </div>
                            <div class="active"></div>
                        </div>
                        <div class="step-row">
                            <div class="title">
                                <p>{{ step.title }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

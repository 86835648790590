<script setup>
// Import vue assets
import { computed, onMounted, ref } from 'vue';
import uniqid from 'uniqid';

// Import steps
import stepOne from "./steps/stepOne.vue";
import stepTwo from "./steps/stepTwo.vue";
import stepThree from './steps/stepThree.vue';
import StepFour from './steps/stepFour.vue';
import stepsCounter from './steps/stepsCounter.vue';

const signUpSteps = ref();
const stepCounter = ref(1);
const packageData = ref({});
const companyData = ref({});
const vacancyData = ref({});
const privacyPageId = ref(null);
const termsPageId = ref(null);
const paymentId = uniqid();
const isSingle = ref(false);

const getSignUpSteps = () => {
    const signUpSteps = document.getElementById('register-vacancy-app').getAttribute('sign-up-steps');
    if (!signUpSteps) {
        return [];
    }

    return JSON.parse(signUpSteps);
}

const getPrivacyAndTermsPage = () => {
    const privacy = document.getElementById('register-vacancy-app').getAttribute('privacy-page');
    const terms = document.getElementById('register-vacancy-app').getAttribute('terms-page');

    return [
        privacy,
        terms,
    ]
}

const setRegisterType = () => {
    const registerSingle = document.getElementById('register-vacancy-app').getAttribute('data-type');

    if (registerSingle) {
        const price = document.getElementById('register-vacancy-app').getAttribute('single-vacancy');
        isSingle.value = true;
        selectPackage({
            packageData: {
                title: 'Enkele vacature',
                price,
                advantages: [],
            },
            step: 2,
        })
    }
}

const selectPackage = (obj) => {
    packageData.value = obj.packageData;
    updateStepCounter(obj.step);
}

const updateCompanyData = (obj) => {
    companyData.value = obj.companyData;
    updateStepCounter(obj.step);
}

const updateVacancyData = (obj) => {
    vacancyData.value = obj.vacancyData;
    updateStepCounter(obj.step);
}

const updateStepCounter = (stepIndex) => {
    stepCounter.value = stepIndex;

    if (stepIndex == 1) {
        document.getElementById('text-under-steps').style.display = 'block';
    } else {
        document.getElementById('text-under-steps').style.display = 'none';
    }
}

const getSteps = computed(() => {
    if (!signUpSteps.value) {
        return [];
    }

    return _.map(signUpSteps.value, (step) => {
        return {
            title: step.step_action,
            image: step.step_image
        }
    })
});

const getPackages = computed(() => {
    if (!signUpSteps.value) {
        return [];
    }

    return signUpSteps.value[0].step_1;
});

onMounted(() => {
    const [privacy, terms] = getPrivacyAndTermsPage();
    privacyPageId.value = privacy;
    termsPageId.value = terms;
    signUpSteps.value = getSignUpSteps().steps;
    setRegisterType()
});

</script>

<template>
    <div class="vue-register-vacancy-form">
        <div class="form-header">
            <stepsCounter :is-single="isSingle" :steps="getSteps" :active-step-id="stepCounter"
                @updateStepCounter="updateStepCounter"></stepsCounter>
        </div>
        <div class="form-body">
            <stepOne v-if="stepCounter == 1" :packages="getPackages" :is-single="isSingle" :steps="getSteps"
                :active-step-id="stepCounter" @selectPackage="selectPackage"></stepOne>
            <stepTwo v-if="stepCounter == 2" :package="packageData" :paymentId="paymentId" :is-single="isSingle"
                :steps="getSteps" :active-step-id="stepCounter" @updateStepCounter="updateCompanyData"></stepTwo>
            <stepThree v-if="stepCounter == 3" :package="packageData" :paymentId="paymentId" :steps="getSteps"
                :is-single="isSingle" :active-step-id="stepCounter" @updateStepCounter="updateVacancyData"></stepThree>
            <StepFour v-if="stepCounter == 4" :privacy-page="privacyPageId" :terms-page="termsPageId" :steps="getSteps"
                :is-single="isSingle" :active-step-id="stepCounter" :paymentId="paymentId" :vacancy-data="vacancyData"
                :package-data="packageData" :company-data="companyData" @updateStepCounter="updateStepCounter"></StepFour>
        </div>
    </div>
</template>
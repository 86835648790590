import { createApp, h } from 'vue';
import registerVacancyForm from './components/registerVacancyForm';
import registerVacancyPaidForm from './components/registerVacancyPaidForm.vue';
import lodash from 'lodash';
// import { ajaxurl } from '../ajax';
window._ = lodash;

const app = createApp({
    render: () => h(registerVacancyForm)
})

if ($('#register-vacancy-app').length) {
    app.mount('#register-vacancy-app');
}

const registerVacancyApp = createApp({
    render: () => h(registerVacancyPaidForm)
})

if ($('#register-vacancy-after-payment-form').length) {
    registerVacancyApp.mount('#register-vacancy-after-payment-form');
}

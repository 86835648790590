<script setup>
import { computed, ref } from 'vue';
import axios from 'axios';
import AnimatedLoader from '../loader.vue';

const emit = defineEmits(['updateStepCounter']);

const policy = ref({
    checked: false,
    error: ''
});
const paymentPopupOpen = ref(false);
const paymentPopupError = ref(false);
const isUploadingContent = ref(false);
const privacyTermsPopupOpen = ref(false);
const privacyTermsDescription = ref('');

const prevStep = () => {
    emit('updateStepCounter', 3);
}

const props = defineProps({
    vacancyData: Object,
    packageData: Object,
    companyData: Object,
    paymentId: String,
    steps: Object,
    activeStepId: Number,
    isSingle: Boolean,
    privacyPage: String,
    termsPage: String,
});

const formatToCurrency = (money) => {
    return money?.toLocaleString('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
    })
};

const validateForm = async () => {
    if (!policy.value.checked) {
        policy.value.error = 'Accepteer de algemene voorwaarden om door te gaan!'
        return;
    }

    if (isUploadingContent.value) {
        return;
    }

    isUploadingContent.value = true;
    submitDataToYellowYard();
}

const initiatePaymentPopup = () => {
    const popupBackground = document.querySelector('.popup-bg');

    popupBackground.classList.add('active');
    paymentPopupOpen.value = true;
}

const handleIdealPayment = () => {
    axios.post('/wp-json/api/v1/create-payment', {
        amount: props.packageData.price,
        title: props.packageData.title,
        invoice_period: props.packageData.invoice_period,
    }).then(response => {
        window.location.href = response.data.payment_url;
    }).catch((error) => {
        alert('Er is iets fout gegaan, neem contact op met de site administrator! Error: ' + error);
    })
}

const submitDataToYellowYard = async () => {
    await axios.post('/wp-json/api/v1/yy/create-entry', {
        amount: props.packageData.price,
        title: props.packageData.title,
        paymentId: props.paymentId,
        vacancy: props.vacancyData,
        company: props.companyData,
        id: props.packageData.id,
        invoice_period: props.packageData.invoice_period,
        package: {
            price: props.packageData.price,
            title: props.packageData.title,
        },
    }).then((response) => {
        initDatalayers(response.data.title, response.data.price, response.data.id);
        initiatePaymentPopup();
    }).catch(() => {
        paymentPopupError.value = true;
        initiatePaymentPopup();
    });
}

const initDatalayers = (title, price, id) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); window.dataLayer.push({
        'event': "purchase",
        'ecommerce': {
            'items': [
                {
                    'item_id': id,
                    'item_name': title,
                    'price': price,
                    'quantity': 1
                }
            ]
        }
    });

}

const openPrivacyTermsPopup = (pageId) => {
    axios.post('/wp-json/api/v1/page/get-content', {
        pageId
    }).then((response) => {
        privacyTermsPopupOpen.value = true;
        privacyTermsDescription.value = response.data.content;
        togglePopupEventListeners()
    })
}

const togglePopupEventListeners = () => {
    const popupBg = document.querySelector('.popup-bg');
    const body = document.querySelector('body');
    const popupCloseBtn = document.querySelector('#popup-privacy-terms .close-popup');

    popupBg.classList.add('active');
    body.classList.add('is-locked');

    popupCloseBtn.addEventListener('click', () => {
        privacyTermsPopupOpen.value = false;
        privacyTermsDescription.value = '';
        popupBg.classList.remove('active');
        body.classList.remove('is-locked');
    });
}

const getActiveAdvantages = computed(() => {
    return _.filter(props.packageData.advantages, (advantage) => advantage.inbegrepen);
});

const getStepTitle = computed(() => {
    if (props.steps.length <= 0) {
        return '';
    }

    return props.steps[props.activeStepId - 1]?.title || '';
});

</script>

<template>
    <div class="vue-form-step step-four">
        <div class="container mwtl">
            <div class="step-wrapper">
                <div class="form-header">
                    <h4>Stap {{ isSingle ? activeStepId - 1 : activeStepId }} van {{ steps.length }}</h4>
                    <h2 class="title">{{ getStepTitle }}</h2>
                </div>
                <div class="column column-left">
                    <div class="summary-container">
                        <div class="summary-section">
                            <h3>Pakket</h3>
                            <div class="inner">
                                <div class="summary-content">
                                    <div class="summary-item">
                                        <h4>Pakket informatie</h4>
                                        <span>{{ packageData.title }}</span>
                                    </div>
                                    <div class="summary-item">
                                        <h4>Prijs</h4>
                                        <span>{{ formatToCurrency(packageData.price * 1.00) }} {{ packageData.invoice_period
                                            == 'onetime' ? ' / eenmalig' : ' / maand (jaarlijks gefactureerd)' }} </span>
                                    </div>
                                    <div class="summary-item" v-if="getActiveAdvantages">
                                        <h4>Voordelen</h4>
                                        <ul>
                                            <li v-for="advantage, advantageIndex in getActiveAdvantages"
                                                :key="advantageIndex">{{ advantage.titel }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary-section">
                            <h3>Bedrijfsinformatie</h3>
                            <div class="inner">
                                <div class="summary-content">
                                    <div class="summary-item">
                                        <h4>Bedrijfsnaam</h4>
                                        <span>{{ companyData.name }}</span>
                                    </div>
                                    <div class="summary-item">
                                        <h4>Website</h4>
                                        <p>{{ companyData.site }}</p>
                                    </div>
                                    <div class="summary-item">
                                        <h4>Telefoonnummer</h4>
                                        <p>{{ companyData.phonenumber }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary-section">
                            <h3>Vacature informatie</h3>
                            <div class="inner">
                                <div class="summary-content">
                                    <div class="summary-item">
                                        <h4>Vacature titel</h4>
                                        <span>{{ vacancyData.title }}</span>
                                    </div>
                                    <div class="summary-item">
                                        <h4>Salaris</h4>
                                        <span>{{ formatToCurrency(vacancyData.salary.min) }} - {{
                                            formatToCurrency(vacancyData.salary.max) }}</span>
                                    </div>
                                    <div class="summary-item">
                                        <h4>Aantal uren</h4>
                                        <span>{{ vacancyData.hours.min }} - {{ vacancyData.hours.max }} uur</span>
                                    </div>
                                    <div class="summary-item">
                                        <h4>Contactpersoon</h4>
                                        <span>{{ vacancyData.contactPerson.firstname }} {{
                                            vacancyData.contactPerson.lastname }}</span>
                                        <span>{{ vacancyData.contactPerson.email }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="summary-section section-back">
                            <button @click="prevStep" class="button alt">Terug</button>
                        </div>
                    </div>
                </div>
                <div class="column column-right">
                    <div class="wrapper">
                        <!-- <div class="payment-method">
                            <h4>Betaalmethode</h4>
                            <div class="options">
                                <div class="option">
                                    <input type="radio" value="ideal" name="payment-method-ideal" v-model="paymentMethod">
                                    <div class="payment-icon">
                                        <img src="/wp-content/themes/designated-child/resources/img/ideal.svg">
                                    </div>
                                    <label for="payment-method-ideal">Betalen met iDeal</label>
                                </div>
                                <div class="option">
                                    <input type="radio" value="banktransfer" name="payment-method-direct"
                                        v-model="paymentMethod">
                                    <div class="payment-icon sepa">
                                        <img src="/wp-content/themes/designated-child/resources/img/banktransfer.svg">
                                    </div>
                                    <label for="payment-method-direct">Betalen d.m.v. overboeken</label>
                                </div>
                            </div>
                        </div> -->
                        <div class="payment-amount">
                            <div class="price-row">
                                <span>Totaal</span>
                                <span>{{ formatToCurrency(props.packageData.title == 'Enkele vacature' ||
                                    props.packageData.invoice_period == 'onetime' ?
                                    props.packageData.price * 1.00 : props.packageData.price * 12.00) }}</span>
                            </div>
                        </div>
                        <div class="accept-policy">
                            <div class="policy-wrapper">
                                <input v-model="policy.checked" type="checkbox" name="accept_policy" id="accept_policy">
                                <label for="accept_policy">Ik ga akkoord met de <a class="open-privacy-popup"
                                        @click.prevent="openPrivacyTermsPopup(termsPage)">gebruikersvoorwaarden</a>. Jouw
                                    privacy is belangrijk voor ons. VasteJob gaat zorgvuldig om met jouw persoonsgegevens,
                                    zie
                                    hierover meer in ons <a class="open-privacy-popup"
                                        @click.prevent="openPrivacyTermsPopup(privacyPage)">privacybeleid</a>.</label>
                            </div>
                            <p v-if="policy.error != ''" class="error-message">{{ policy.error }}</p>
                        </div>
                        <div class="pay-button">
                            <button @click="validateForm" class="button">
                                <AnimatedLoader v-if="isUploadingContent"></AnimatedLoader>Gegevens verzenden
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div :class="{ 'active': paymentPopupOpen }" class="popup package-popup">
            <div class="popup-content-wrapper">
                <div class="content">
                    <h3 v-if="!paymentPopupError" class="title">Gelukt, bedankt voor je aanmelding!</h3>
                    <h3 v-if="paymentPopupError" class="title">Er is iets fout gegaan!</h3>
                    <p v-if="!paymentPopupError" class="subtitle">Als je nu gelijk betaalt, kunnen we je vacature sneller
                        publiceren. Het is ook
                        mogelijk om achteraf te betalen op factuur.</p>
                    <p v-if="paymentPopupError" class="subtitle">Excuses voor het ongemak. Er is een probleem opgetreden met
                        uw verzoek. Neem voor snelle assistentie contact op met onze sitebeheerder via
                        <a href="mailto:info@vastejob.nl">info@vastejob.nl</a>. Dank voor uw geduld en begrip.
                        <br><br>
                        Met vriendelijke groet, VasteJob
                    </p>
                    <div v-if="!paymentPopupError" class="options">
                        <button @click="handleIdealPayment" class="button primary">Direct betalen met iDeal</button>
                        <a href="/werkgevers" class="button secondary alt">Achteraf betalen op
                            factuur</a>
                    </div>
                </div>
            </div>
        </div>

        <div id="popup-privacy-terms" :class="{ 'active': privacyTermsPopupOpen }"
            class="popup popup-privacy-terms package-popup">
            <div class="close-popup">
                <span>Sluiten</span>
                <div class="close-icon">
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
            <div class="popup-content-wrapper">
                <div class="content">
                    <div class="description" v-html="privacyTermsDescription"></div>
                </div>
            </div>
        </div>
    </div>
</template>

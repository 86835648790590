<script setup>
import { computed, onMounted, ref } from 'vue';
import axios from 'axios';

const ajaxUrl = ref(null);
const imageUrl = ref(null);
const isCreatingVacancy = ref(false);
const organizationId = ref({
    id: null,
    error: false,
});

const vacancyTitle = ref({
    error: '',
    text: ''
});
const vacancyDescription = ref({
    error: '',
    text: ''
});
const vacancySalary = ref({
    error: '',
    min: 0,
    max: 0,
});
const vacancyHours = ref({
    error: '',
    min: 0,
    max: 0,
});
const vacancyContactPersonEmail = ref({
    error: '',
    text: ''
});
const vacancyContactPersonFirstname = ref({
    error: '',
    text: ''
});
const vacancyContactPersonLastname = ref({
    error: '',
    text: ''
});
const vacancyBranch = ref({
    error: '',
    text: ''
});
const vacancyEducation = ref({
    error: '',
    text: ''
});
const acceptedPolicies = ref({
    error: '',
    text: false
});

const validateVacancyData = () => {
    let isValid = true;

    if (vacancyTitle.value.text == '') {
        isValid = false;
        vacancyTitle.value.error = 'Geef een vacature titel op'
    }

    if (vacancyDescription.value.text == '') {
        isValid = false;
        vacancyDescription.value.error = 'Geef een vacature beschrijving op';
    }

    if (vacancySalary.value.min <= 0 || vacancySalary.value.min == '' || vacancySalary.value.max == '' || vacancySalary.value.max < vacancySalary.value.min) {
        isValid = false;
        vacancySalary.value.error = 'Geef een vacature salaris op';
    }

    if (vacancyHours.value.min <= 0 || vacancyHours.value.min == '' || vacancyHours.value.max == '' || vacancyHours.value.max < vacancyHours.value.min) {
        isValid = false;
        vacancyHours.value.error = 'Geef een vacature uren op';
    }

    if (vacancyContactPersonEmail.value.text == '') {
        isValid = false;
        vacancyContactPersonEmail.value.error = 'Geef het e-mailadres van de contactpersoon op';
    }

    if (vacancyContactPersonFirstname.value.text == '') {
        isValid = false;
        vacancyContactPersonFirstname.value.error = 'Geef de voornaam van de contactpersoon op';
    }

    if (vacancyContactPersonLastname.value.text == '') {
        isValid = false;
        vacancyContactPersonLastname.value.error = 'Geef de achternaam van de contactpersoon op';
    }

    if (vacancyBranch.value.text == '' || vacancyBranch.value.text == 'none') {
        isValid = false;
        vacancyBranch.value.error = 'Geef een vacature branche op';
    }

    if (vacancyEducation.value.text == '' || vacancyEducation.value.text == 'none') {
        isValid = false;
        vacancyEducation.value.error = 'Geef een opleidingsniveau op';
    }

    if (!acceptedPolicies.value.text) {
        isValid = false;
        acceptedPolicies.value.error = 'Accepteer de algemene voorwaarden om de stap af te ronden';
    }

    if (organizationId.value.error || isCreatingVacancy.value) {
        isValid = false;
    }

    return isValid;
}

const nextStep = () => {
    if (validateVacancyData()) {
        postVacancy();
    }
}

const setYellowYardIds = () => {
    const params = new URLSearchParams(window.location.search)
    const oiKey = params.get('organisationId') ?? null;

    if (!oiKey) {
        organizationId.value.error = true;
        return;
    }

    organizationId.value.id = oiKey;
};


const setAjaxUrl = () => {
    const ajax = document.getElementById('register-vacancy-after-payment-form').getAttribute('data-ajax');

    ajaxUrl.value = ajax;
}

const setImageUrl = () => {
    const image = document.getElementById('register-vacancy-after-payment-form').getAttribute('image-url');

    imageUrl.value = image;
}

const postVacancy = () => {
    isCreatingVacancy.value = true;

    axios.post('/wp-json/api/v1/create-vacancy', {
        vacancyData: {
            vacancy: {
                title: vacancyTitle.value.text,
                description: vacancyDescription.value.text,
                salary: {
                    min: vacancySalary.value.min,
                    max: vacancySalary.value.max
                },
                hours: {
                    min: vacancyHours.value.min,
                    max: vacancyHours.value.max,
                },
                contactPerson: {
                    email: vacancyContactPersonEmail.value.text,
                    firstname: vacancyContactPersonFirstname.value.text,
                    lastname: vacancyContactPersonLastname.value.text
                },
                branch: vacancyBranch.value.text,
                education: vacancyEducation.value.text,
            },
            organisationId: organizationId.value.id
        }
    })
        .then((response) => {
            window.location.href = "/nog-een-vacature-aanmelden-geslaagd"
        }).catch((error) => {
            console.log(error);
        })
}

const getButtonText = computed(() => {
    return isCreatingVacancy.value ? 'Vacature aanmaken....' : 'Vacature aanmaken';
});

onMounted(() => {
    setYellowYardIds();
    setAjaxUrl();
    setImageUrl();
})

</script>

<template>
    <div class="vue-form-step step-three single-vacancy register-vacancy-after-payment">
        <div class="container">
            <div class="step-wrapper">
                <div v-if="imageUrl" class="column column-left">
                    <div class="image-wrapper">
                        <img :src="imageUrl" class="step-image" alt="Stap Afbeelding">
                    </div>
                </div>
                <div :class="{ 'full': !imageUrl }" class="column column-right">
                    <div class="form-step-wrapper">
                        <div class="form">
                            <div class="form-header">
                                <h2>Vacature informatie</h2>
                            </div>
                            <div class="form-body">
                                <div class="field-item type-text vacancy-title full">
                                    <label class="form-item-label" for="vacancy_title">Vacature titel <span>*</span></label>
                                    <input v-model="vacancyTitle.text" type="text" name="vacancy_title"
                                        class="form-item-input" placeholder="Vul hier de vacature titel in">
                                    <p v-if="vacancyTitle.error != ''" class="error-message">{{ vacancyTitle.error }}</p>
                                </div>
                                <div class="field-item type-textarea vacancy-description full">
                                    <label class="form-item-label" for="vacancy_description">Vacature
                                        beschrijving<span>*</span></label>
                                    <textarea v-model="vacancyDescription.text" rows="6" name="vacancy_description"
                                        class="form-item-input"
                                        placeholder="Vul hier de vacature beschrijving in"></textarea>
                                    <p v-if="vacancyDescription.error != ''" class="error-message">{{
                                        vacancyDescription.error }}</p>
                                </div>
                                <div class="field-item type-range salary-range half">
                                    <label class="form-item-label" for="vacancy_salary_range_min">Salarisrange
                                        <span>*</span></label>
                                    <div class="range-wrapper">
                                        <input v-model="vacancySalary.min" type="number" name="vacancy_salary_range_min"
                                            class="form-item-input" placeholder="Min.">
                                        <input v-model="vacancySalary.max" type="number" name="vacancy_salary_range_max"
                                            class="form-item-input" placeholder="Max.">
                                    </div>
                                    <p v-if="vacancySalary.error != ''" class="error-message">{{ vacancySalary.error }}</p>
                                </div>
                                <div class="field-item type-text hours-week half">
                                    <label class="form-item-label" for="vacancy_hours">Aantal uren per week
                                        <span>*</span></label>
                                    <div class="range-wrapper">
                                        <input v-model="vacancyHours.min" type="number" name="vacancy_hours_range_min"
                                            class="form-item-input" placeholder="Min.">
                                        <input v-model="vacancyHours.max" type="number" name="vacancy_hours_range_max"
                                            class="form-item-input" placeholder="Max.">
                                    </div>
                                    <p v-if="vacancyHours.error != ''" class="error-message">{{ vacancyHours.error }}</p>
                                </div>
                                <div class="field-item type-text contact-person-firstname half">
                                    <label class="form-item-label" for="vacancy_contact_person_firstname">Voornaam van
                                        contactpersoon <span>*</span></label>
                                    <input v-model="vacancyContactPersonFirstname.text" type="text"
                                        name="vacancy_contact_person_firstname" class="form-item-input" placeholder="John">
                                    <p v-if="vacancyContactPersonFirstname.error != ''" class="error-message">{{
                                        vacancyContactPersonFirstname.error }}</p>
                                </div>
                                <div class="field-item type-text contact-person-lastname half">
                                    <label class="form-item-label" for="vacancy_contact_person_lastname">Achternaam van
                                        contactpersoon <span>*</span></label>
                                    <input v-model="vacancyContactPersonLastname.text" type="text"
                                        name="vacancy_contact_person_lastname" class="form-item-input" placeholder="Doe">
                                    <p v-if="vacancyContactPersonLastname.error != ''" class="error-message">{{
                                        vacancyContactPersonLastname.error }}</p>
                                </div>
                                <div class="field-item type-text contact-person-email full">
                                    <label class="form-item-label" for="vacancy_contact_person_email">Email van
                                        contactpersoon <span>*</span></label>
                                    <input v-model="vacancyContactPersonEmail.text" type="text"
                                        name="vacancy_contact_person_email" class="form-item-input"
                                        placeholder="johndoe@emailadres.nl">
                                    <p v-if="vacancyContactPersonEmail.error != ''" class="error-message">{{
                                        vacancyContactPersonEmail.error }}</p>
                                </div>
                                <div class="field-item type-dropdown branch-dropdown half">
                                    <label class="form-item-label" for="vacancy_branch">Branche <span>*</span></label>
                                    <div class="select-dropdown-wrapper">
                                        <select v-model="vacancyBranch.text" class="branch-selector" name="vacancy_branch">
                                            <option value="none" selected>&gt; Maak uw keuze</option>
                                            <option value="Administratie &amp; Financieel">Administratie &amp; Financieel
                                            </option>
                                            <option value="Beveiliging &amp; Handhaving">Beveiliging &amp; Handhaving
                                            </option>
                                            <option value="Bouw">Bouw</option>
                                            <option value="Communicatie &amp; Journalistiek">Communicatie &amp;
                                                Journalistiek</option>
                                            <option value="Detailhandel">Detailhandel</option>
                                            <option value="Horeca &amp; Catering">Horeca &amp; Catering</option>
                                            <option value="Human Resource Management">Human Resource Management</option>
                                            <option value="ICT &amp; Automatisering">ICT &amp; Automatisering</option>
                                            <option value="Juridisch">Juridisch</option>
                                            <option value="Klantenservice">Klantenservice</option>
                                            <option value="Kwaliteit &amp; Veiligheid">Kwaliteit &amp; Veiligheid</option>
                                            <option value="Logistiek, Transport &amp; Inkoop">Logistiek, Transport &amp;
                                                Inkoop</option>
                                            <option value="Management, Beleid &amp; Organisatie">Management, Beleid &amp;
                                                Organisatie</option>
                                            <option value="Marketing &amp; Creatief">Marketing &amp; Creatief</option>
                                            <option value="Natuur, Milieu &amp; Agrarisch">Natuur, Milieu &amp; Agrarisch
                                            </option>
                                            <option value="Onderwijs &amp; Kinderopvang">Onderwijs &amp; Kinderopvang
                                            </option>
                                            <option value="Onderzoek &amp; Wetenschap">Onderzoek &amp; Wetenschap</option>
                                            <option value="Productie">Productie</option>
                                            <option value="Schoonmaak &amp; Facilitair">Schoonmaak &amp; Facilitair</option>
                                            <option value="Sales">Sales</option>
                                            <option value="Sport, Recreatie &amp; Reizen">Sport, Recreatie &amp; Reizen
                                            </option>
                                            <option value="Techniek" selected="selected">Techniek</option>
                                            <option value="Zorg &amp; Welzijn">Zorg &amp; Welzijn</option>
                                            <option value="Groenvoorziening">Groenvoorziening</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="field-item type-dropdown education-dropdown half">
                                    <label class="form-item-label" for="vacancy_education">Opleidingsniveau
                                        <span>*</span></label>
                                    <div class="select-dropdown-wrapper">
                                        <select v-model="vacancyEducation.text" class="education-selector"
                                            name="vacancy_education">
                                            <option selected value="none">Selecteer opleidingsniveau</option>
                                            <option value="VMBO">VMBO</option>
                                            <option value="LBO">LBO</option>
                                            <option value="HAVO">HAVO</option>
                                            <option value="MBO">MBO</option>
                                            <option value="HBO">HBO</option>
                                            <option value="HBO">VWO</option>
                                            <option value="WO / Universitair">WO / Universitair</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="field-item type-checkmark accept-policies full">
                                    <input v-model="acceptedPolicies.text" type="checkbox" name="accept_policy">
                                    <label class="form-item-label" for="accept_policy">Ik ga akkoord met de <a
                                            href="#">gebruikersvoorwaarden.</a> Jouw privacy is belangrijk voor ons.
                                        VasteJob gaat zorgvuldig om met jouw persoonsgegevens, zie hierover meer in ons <a
                                            href="#">privacybeleid</a>.</label>
                                    <p v-if="acceptedPolicies.error != ''" class="error-message">{{ acceptedPolicies.error
                                    }}</p>
                                </div>
                                <div v-if="organizationId.error" class="field-item">
                                    <p class="error-message">Er is iets fout gegaan, probeer het later opnieuw!</p>
                                </div>
                                <div class="buttons-wrapper">
                                    <button :disabled="organizationId.error || isCreatingVacancy" @click="nextStep"
                                        class="button">{{ getButtonText }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
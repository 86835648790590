<script setup>
// Vue assets
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

// Components
import DropZone from '../dropzone.vue';

const props = defineProps({
    paymentId: {
        type: String,
        required: true
    },
    steps: {
        type: Object,
        required: true
    },
    activeStepId: {
        type: Number,
        required: true
    },
    isSingle: {
        type: Boolean,
        required: true
    },
    package: Object,
})

const emit = defineEmits(['updateStepCounter']);

const companyName = ref({
    text: '',
    error: ''
});
const companyLogo = ref({
    file: [],
    error: '',
    url: ''
});
const companySite = ref({
    text: '',
    error: ''
});
const companyPhonenumber = ref({
    text: '',
    error: ''
});
const companyDescription = ref({
    text: '',
    error: ''
});
const companyAssetsEnabled = ref(true);
const companyAssets = ref({
    assets: [],
    urls: [],
    error: '',
});

const validateCompanyData = () => {
    let isValid = true;

    if (companyName.value.text == '') {
        isValid = false;
        companyName.value.error = 'Geef een bedrijfsnaam op'
    }

    if (companyLogo.value.file == '') {
        isValid = false;
        companyLogo.value.error = 'Upload een bedrijfslogo';
    }

    if (companySite.value.text == '') {
        isValid = false;
        companySite.value.error = 'Geef de url van het bedrijf op!';
    }

    if (companyPhonenumber.value.text == '') {
        isValid = false;
        companyPhonenumber.value.error = 'Geef een geldig telefoonnummer op!';
    }

    if (companyDescription.value.text == '') {
        isValid = false;
        companyDescription.value.error = 'Geef de omschrijving van de organisatie op!';
    }

    return isValid;
}

const updateLogoFiles = (files) => {
    companyLogo.value.file = files[0];
}

const updateAssetFiles = (files) => {
    companyAssets.value.assets = files;
}

const nextStep = async () => {
    if (validateCompanyData()) {
        companyLogo.value.url = await uploadTempFiles([companyLogo.value.file], props.paymentId);

        if (companyAssets.value.assets.length > 0) {
            companyAssets.value.urls = await uploadTempFiles(companyAssets.value.assets, props.paymentId);
        }

        emit('updateStepCounter', {
            step: 3,
            companyData: {
                name: companyName.value.text,
                logo: companyLogo.value.url,
                site: companySite.value.text,
                phonenumber: companyPhonenumber.value.text,
                description: companyDescription.value.text,
                assetsEnabled: companyAssetsEnabled.value,
                assets: companyAssets.value.urls,
            }
        });
    }
}

const uploadTempFiles = async (filesArray, paymentId) => {
    const formData = new FormData();
    for (const file of filesArray) {
        formData.append('files[]', file);
    }
    formData.append('action', 'temp_file_upload');
    formData.append('paymentId', paymentId);

    try {
        const response = await axios.post('/wp-admin/admin-ajax.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data.urls; // Array of image URLs
    } catch (error) {
        console.error('Temporary upload failed:', error);
        return null;
    }
};

const getStepTitle = computed(() => {
    if (props.steps.length <= 0) {
        return '';
    }

    if (props.isSingle) {
        return props.steps[props.activeStepId - 2]?.title || '';
    }

    return props.steps[props.activeStepId - 1]?.title || '';
});

const getStepImage = computed(() => {
    if (props.steps.length <= 0) {
        return '';
    }

    if (props.isSingle) {
        return props.steps[props.activeStepId - 2]?.image || false;
    }

    return props.steps[props.activeStepId - 1]?.image || false;
});


const prevStep = () => {
    emit('updateStepCounter', { step: 1, companyData: {} });
}

onMounted(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); window.dataLayer.push({
        'event': "add_to_cart",
        'ecommerce': {
            'items': [
                {
                    'item_id': props.package.id,
                    'item_name': props.package.title,
                    'price': props.package.price,
                    'quantity': 1
                }
            ]
        }
    });
});

</script>

<template>
    <div class="vue-form-step step-two">
        <div class="container">
            <div class="step-wrapper">
                <div v-if="getStepImage" class="column column-left">
                    <div class="image-wrapper">
                        <img :src="getStepImage" class="step-image" alt="Stap 2 Afbeelding">
                    </div>
                </div>
                <div :class="{ 'full': !getStepImage }" class="column column-right">
                    <div class="form-step-wrapper">
                        <div class="form">
                            <div class="form-header">
                                <h4>Stap {{ isSingle ? activeStepId - 1 : activeStepId }} van {{ steps.length }}</h4>
                                <h2 class="title">{{ getStepTitle }}</h2>
                            </div>
                            <div class="form-body">
                                <div class="field-item type-text company-title half">
                                    <label class="form-item-label" for="company_name">Bedrijfsnaam <span>*</span></label>
                                    <input v-model="companyName.text" type="text" name="company_name"
                                        class="form-item-input" placeholder="Vul hier de bedrijfsnaam in">
                                    <p v-if="companyName.error != ''" class="error-message">{{ companyName.error }}</p>
                                </div>
                                <div class="field-item type-upload logo-upload half">
                                    <label class="form-item-label" for="logo_upload">Logo <span>*</span></label>
                                    <div class="file-upload-wrapper">
                                        <DropZone accept-type="image/*" :limit="1" @update-files="updateLogoFiles">
                                        </DropZone>
                                        <p v-if="companyLogo.error != ''" class="error-message">{{ companyLogo.error }}</p>
                                    </div>
                                </div>
                                <div class="field-item type-url website-url half">
                                    <label class="form-item-label" for="company_site">Website <span>*</span></label>
                                    <input v-model="companySite.text" type="text" name="company_site"
                                        class="form-item-input" placeholder="Vul hier de URL van je bedrijf in">
                                    <p v-if="companySite.error != ''" class="error-message">{{ companySite.error }}</p>
                                </div>
                                <div class="field-item type-phone phone-number half">
                                    <label class="form-item-label" for="company_phonenumber">Telefoonnummer
                                        <span>*</span></label>
                                    <input v-model="companyPhonenumber.text" type="text" name="company_phonenumber"
                                        class="form-item-input" placeholder="Vul hier het telefoonnummer van je bedrijf in">
                                    <p v-if="companyPhonenumber.error != ''" class="error-message">{{
                                        companyPhonenumber.error }}</p>
                                </div>
                                <div class="field-item type-textarea company-desciption full">
                                    <label class="form-item-label" for="company_description">Omschrijving organisatie
                                        <span>*</span></label>
                                    <textarea v-model="companyDescription.text" rows="6" name="company_description"
                                        class="form-item-input"
                                        placeholder="Vul hier een beschrijving van je organisatie in"></textarea>
                                    <p v-if="companyDescription.error != ''" class="error-message">{{
                                        companyDescription.error }}</p>
                                </div>
                                <div class="field-group has-child-below file-upload full">
                                    <div class="field-item">
                                        <label class="form-item-label" for="company_description">Foto's en/of
                                            video's</label>
                                        <div class="radio-group">
                                            <div class="group-item">
                                                <input @click="companyAssetsEnabled = true" checked type="radio"
                                                    name="company_assets" value="yes" id="yes">
                                                <label for="yes">Ja, ik wil deze uploaden</label>
                                            </div>
                                            <div class="group-item">
                                                <input @click="companyAssetsEnabled = false" type="radio"
                                                    name="company_assets" value="no" id="no">
                                                <label for="no">Nee, heb ik niet</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-item" v-show="companyAssetsEnabled">
                                        <div class="file-upload-wrapper dropdown-wrapper">
                                            <DropZone accept-type="image/*,video/*" @update-files="updateAssetFiles">
                                            </DropZone>
                                            <p v-if="companyAssets.error != ''" class="error-message">{{ companyAssets.error
                                            }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="buttons-wrapper">
                                    <button @click="prevStep" class="button alt">Vorige</button>
                                    <button @click="nextStep" class="button">Volgende</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed } from 'vue';

const emit = defineEmits(['selectPackage']);

const props = defineProps({
    packages: {
        type: Array,
        required: true
    },
    steps: {
        type: Object,
        required: true
    },
    activeStepId: {
        type: Number,
        required: true
    },
    isSingle: {
        type: Boolean,
        required: true,
    }
});

const getStepTitle = computed(() => {
    if (props.steps.length <= 0) {
        return '';
    }

    if (props.isSingle) {
        return props.steps[props.activeStepId - 2]?.title || '';
    }

    return props.steps[props.activeStepId - 1]?.title || '';
});

const selectPackage = (packageData, id) => {
    packageData['id'] = id;
    initDatalayer(packageData, id);
    emit('selectPackage', {
        step: 2,
        packageData,
    });
}

const initDatalayer = (packageData, id) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); window.dataLayer.push({
        'event': "select_item",
        'ecommerce': {
            'items': [
                {
                    'item_id': id,
                    'item_name': packageData.title,
                    'price': packageData.price,
                    'quantity': 1,
                }
            ]
        }
    });

}
</script>

<template>
    <div class="vue-form-step step-one">
        <div class="step-one-wrapper">
            <div class="container">
                <div class="packages">
                    <div class="packages-wrapper">
                        <div class="header">
                            <h4>Stap {{ activeStepId }} van {{ steps.length }}</h4>
                            <h2 class="title">{{ getStepTitle }}</h2>
                        </div>
                        <div class="content">
                            <div class="package" :class="{ 'popular': vPackage == 2 }" v-for="vPackage, vIndex in packages"
                                :key="vPackage">
                                <div class="package-title">
                                    <h3>{{ vPackage.title }}</h3>
                                    <p>{{ vPackage.audience }}</p>
                                </div>
                                <div class="package-price">
                                    <h3><span class="price-icon">€</span> {{ vPackage.price }}</h3>
                                    <span class="monthly">{{ vPackage.invoice_period == 'onetime' ? '/ eenmalig' : '/ maand'
                                    }}</span>
                                    <span class="billing">* {{ vPackage.invoice_period == 'onetime' ? 'Eenmalig' :
                                        'Jaarlijks' }} gefactureerd</span>
                                </div>
                                <div class="package-advantages">
                                    <ul class="list">
                                        <li v-for="advantage, index in vPackage.advantages" :key="index" class="title"
                                            :data-package-title="advantage.titel"
                                            :data-package-description="advantage.extra_info"
                                            :class="{ 'enabled': advantage.inbegrepen == true }"> <span>{{ advantage.titel
                                            }}</span><i class="fa-solid fa-circle-info open-package-popup"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div class="select-package">
                                    <button @click="selectPackage(vPackage, vIndex)" class="button">Kies pakket</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="popup package-popup">
            <div class="close-popup">
                <span>Sluiten</span>
                <div class="close-icon">
                    <i class="fa-solid fa-xmark"></i>
                </div>
            </div>
            <div class="popup-content-wrapper">
                <div class="content">
                    <h3 class="title"></h3>
                    <div class="description"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import Dropzone from '../dropzone.vue';

const props = defineProps({
    steps: {
        type: Object,
        required: true
    },
    activeStepId: {
        type: Number,
        required: true
    },
    isSingle: {
        type: Boolean,
        required: true
    },
    paymentId: {
        type: String,
        required: true
    },
    package: Object,
});

const emit = defineEmits(['updateStepCounter']);

const vacancyTitle = ref({
    text: '',
    error: '',
});
const vacancyDescription = ref({
    text: '',
    error: ''
});
const vacancySalary = ref({
    error: '',
    min: null,
    max: null
});
const vacancyHours = ref({
    error: '',
    min: null,
    max: null,
});
const vacancyContactPersonEmail = ref({
    text: '',
    error: '',
});
const vacancyContactPersonFirstname = ref({
    text: '',
    error: '',
});
const vacancyContactPersonLastname = ref({
    text: '',
    error: ''
});
const vacancyBranch = ref({
    text: '',
    error: ''
});
const vacancyEducation = ref({
    text: '',
    error: ''
});
const vacancyAssetsEnabled = ref(true);
const vacancyAssets = ref({
    assets: [],
    urls: [],
    error: '',
});

const updateAssetFiles = (files) => {
    vacancyAssets.value.assets = files;
}

const uploadTempFiles = async (filesArray, paymentId) => {
    const formData = new FormData();
    for (const file of filesArray) {
        formData.append('files[]', file);
    }
    formData.append('action', 'temp_file_upload');
    formData.append('paymentId', paymentId);

    try {
        const response = await axios.post('/wp-admin/admin-ajax.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data.urls; // Array of image URLs
    } catch (error) {
        console.error('Temporary upload failed:', error);
        return null;
    }
};

const validateVacancyData = () => {
    let isValid = true;

    if (vacancyTitle.value.text == '') {
        isValid = false;
        vacancyTitle.value.error = 'Geef een vacature titel op'
    }

    if (vacancyDescription.value.text == '') {
        isValid = false;
        vacancyDescription.value.error = 'Geef een vacature beschrijving op';
    }

    if (vacancySalary.value.min <= 0 || vacancySalary.value.min == '' || vacancySalary.value.max == '' || vacancySalary.value.max < vacancySalary.value.min) {
        isValid = false;
        vacancySalary.value.error = 'Geef een vacature salaris op';
    }

    if (vacancyHours.value.min <= 0 || vacancyHours.value.min == '' || vacancyHours.value.max == '' || vacancyHours.value.max < vacancyHours.value.min) {
        isValid = false;
        vacancyHours.value.error = 'Geef een vacature uren op';
    }

    if (vacancyContactPersonEmail.value.text == '') {
        isValid = false;
        vacancyContactPersonEmail.value.error = 'Geef het e-mailadres van de contactpersoon op';
    }

    if (vacancyContactPersonFirstname.value.text == '') {
        isValid = false;
        vacancyContactPersonFirstname.value.error = 'Geef de voornaam van de contactpersoon op';
    }

    if (vacancyContactPersonLastname.value.text == '') {
        isValid = false;
        vacancyContactPersonLastname.value.error = 'Geef de achternaam van de contactpersoon op';
    }

    if (vacancyBranch.value.text == '' || vacancyBranch.value.text == 'none') {
        isValid = false;
        vacancyBranch.value.error = 'Geef een vacature branche op';
    }

    if (vacancyEducation.value.text == '' || vacancyEducation.value.text == 'none') {
        isValid = false;
        vacancyEducation.value.error = 'Geef een opleidingsniveau op';
    }

    return isValid;
}

const nextStep = async () => {
    if (validateVacancyData()) {
        if (vacancyAssets.value.assets.length > 0) {
            vacancyAssets.value.urls = await uploadTempFiles(vacancyAssets.value.assets, props.paymentId);
        }

        emit('updateStepCounter', {
            step: 4,
            vacancyData: {
                title: vacancyTitle.value.text,
                description: vacancyDescription.value.text,
                salary: {
                    min: vacancySalary.value.min,
                    max: vacancySalary.value.max
                },
                hours: {
                    min: vacancyHours.value.min,
                    max: vacancyHours.value.max,
                },
                contactPerson: {
                    email: vacancyContactPersonEmail.value.text,
                    firstname: vacancyContactPersonFirstname.value.text,
                    lastname: vacancyContactPersonLastname.value.text
                },
                images: vacancyAssets.value.urls,
                branch: vacancyBranch.value.text,
                education: vacancyEducation.value.text
            }
        });
    }
}

const prevStep = () => {
    emit('updateStepCounter', { step: 2, vacancyData: {} });
}

const getStepTitle = computed(() => {
    if (props.steps.length <= 0) {
        return '';
    }

    if (props.isSingle) {
        return props.steps[props.activeStepId - 2]?.title || '';
    }

    return props.steps[props.activeStepId - 1]?.title || '';
});

const getStepImage = computed(() => {
    if (props.steps.length <= 0) {
        return '';
    }

    if (props.isSingle) {
        return props.steps[props.activeStepId - 2]?.image || false;
    }

    return props.steps[props.activeStepId - 1]?.image || false;
});

onMounted(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); window.dataLayer.push({
        'event': "begin_checkout",
        'ecommerce': {
            'items': [
                {
                    'item_id': props.package.id,
                    'item_name': props.package.title,
                    'price': props.package.price,
                    'quantity': 1
                }
            ]
        }
    });
})

</script>

<template>
    <div class="vue-form-step step-three">
        <div class="container">
            <div class="step-wrapper">
                <div v-if="getStepImage" class="column column-left">
                    <div class="image-wrapper">
                        <img :src="getStepImage" class="step-image" alt="Stap 3 Afbeelding">
                    </div>
                </div>
                <div :class="{ 'full': !getStepImage }" class="column column-right">
                    <div class="form-step-wrapper">
                        <div class="form">
                            <div class="form-header">
                                <h4>Stap {{ isSingle ? activeStepId - 1 : activeStepId }} van {{ steps.length }}</h4>
                                <h2 class="title">{{ getStepTitle }}</h2>
                            </div>
                            <div class="form-body">
                                <div class="field-item type-text vacancy-title full">
                                    <label class="form-item-label" for="vacancy_title">Vacature titel <span>*</span></label>
                                    <input v-model="vacancyTitle.text" type="text" name="vacancy_title"
                                        class="form-item-input" placeholder="Vul hier de vacature titel in">
                                    <p v-if="vacancyTitle.error != ''" class="error-message">{{ vacancyTitle.error }}</p>
                                </div>
                                <div class="field-item type-textarea vacancy-description full">
                                    <label class="form-item-label" for="vacancy_description">Vacature
                                        beschrijving<span>*</span></label>
                                    <textarea v-model="vacancyDescription.text" rows="6" name="vacancy_description"
                                        class="form-item-input"
                                        placeholder="Vul hier de vacature beschrijving in"></textarea>
                                    <p v-if="vacancyDescription.error != ''" class="error-message">{{
                                        vacancyDescription.error }}</p>
                                </div>
                                <div class="field-item type-range salary-range half">
                                    <label class="form-item-label" for="vacancy_salary_range_min">Salarisrange (per maand)
                                        <span>*</span></label>
                                    <div class="range-wrapper">
                                        <input v-model="vacancySalary.min" type="number" name="vacancy_salary_range_min"
                                            class="form-item-input" placeholder="Min.">
                                        <input v-model="vacancySalary.max" type="number" name="vacancy_salary_range_max"
                                            class="form-item-input" placeholder="Max.">
                                    </div>
                                    <p v-if="vacancySalary.error != ''" class="error-message">{{ vacancySalary.error }}</p>
                                </div>
                                <div class="field-item type-text hours-week half">
                                    <label class="form-item-label" for="vacancy_hours">Aantal uren per week
                                        <span>*</span></label>
                                    <div class="range-wrapper">
                                        <input v-model="vacancyHours.min" type="number" name="vacancy_hours_range_min"
                                            class="form-item-input" placeholder="Min.">
                                        <input v-model="vacancyHours.max" type="number" name="vacancy_hours_range_max"
                                            class="form-item-input" placeholder="Max.">
                                    </div>
                                    <p v-if="vacancyHours.error != ''" class="error-message">{{ vacancyHours.error }}</p>
                                </div>
                                <div class="field-item type-text contact-person-firstname half">
                                    <label class="form-item-label" for="vacancy_contact_person_firstname">Voornaam van
                                        contactpersoon <span>*</span></label>
                                    <input v-model="vacancyContactPersonFirstname.text" type="text"
                                        name="vacancy_contact_person_firstname" class="form-item-input" placeholder="John">
                                    <p v-if="vacancyContactPersonFirstname.error != ''" class="error-message">{{
                                        vacancyContactPersonFirstname.error }}</p>
                                </div>
                                <div class="field-item type-text contact-person-lastname half">
                                    <label class="form-item-label" for="vacancy_contact_person_lastname">Achternaam van
                                        contactpersoon <span>*</span></label>
                                    <input v-model="vacancyContactPersonLastname.text" type="text"
                                        name="vacancy_contact_person_lastname" class="form-item-input" placeholder="Doe">
                                    <p v-if="vacancyContactPersonLastname.error != ''" class="error-message">{{
                                        vacancyContactPersonLastname.error }}</p>
                                </div>
                                <div class="field-item type-text contact-person-email full">
                                    <label class="form-item-label" for="vacancy_contact_person_email">Email van
                                        contactpersoon <span>*</span></label>
                                    <input v-model="vacancyContactPersonEmail.text" type="text"
                                        name="vacancy_contact_person_email" class="form-item-input"
                                        placeholder="johndoe@emailadres.nl">
                                    <p v-if="vacancyContactPersonEmail.error != ''" class="error-message">{{
                                        vacancyContactPersonEmail.error }}</p>
                                </div>
                                <div class="field-item type-dropdown branch-dropdown half">
                                    <label class="form-item-label" for="vacancy_branch">Branche <span>*</span></label>
                                    <div class="select-dropdown-wrapper">
                                        <select v-model="vacancyBranch.text" class="branch-selector" name="vacancy_branch">
                                            <option value="none" selected>&gt; Maak uw keuze</option>
                                            <option value="Administratie &amp; Financieel">Administratie &amp; Financieel
                                            </option>
                                            <option value="Beveiliging &amp; Handhaving">Beveiliging &amp; Handhaving
                                            </option>
                                            <option value="Bouw">Bouw</option>
                                            <option value="Communicatie &amp; Journalistiek">Communicatie &amp;
                                                Journalistiek</option>
                                            <option value="Detailhandel">Detailhandel</option>
                                            <option value="Horeca &amp; Catering">Horeca &amp; Catering</option>
                                            <option value="Human Resource Management">Human Resource Management</option>
                                            <option value="ICT &amp; Automatisering">ICT &amp; Automatisering</option>
                                            <option value="Juridisch">Juridisch</option>
                                            <option value="Klantenservice">Klantenservice</option>
                                            <option value="Kwaliteit &amp; Veiligheid">Kwaliteit &amp; Veiligheid</option>
                                            <option value="Logistiek, Transport &amp; Inkoop">Logistiek, Transport &amp;
                                                Inkoop</option>
                                            <option value="Management, Beleid &amp; Organisatie">Management, Beleid &amp;
                                                Organisatie</option>
                                            <option value="Marketing &amp; Creatief">Marketing &amp; Creatief</option>
                                            <option value="Natuur, Milieu &amp; Agrarisch">Natuur, Milieu &amp; Agrarisch
                                            </option>
                                            <option value="Onderwijs &amp; Kinderopvang">Onderwijs &amp; Kinderopvang
                                            </option>
                                            <option value="Onderzoek &amp; Wetenschap">Onderzoek &amp; Wetenschap</option>
                                            <option value="Productie">Productie</option>
                                            <option value="Schoonmaak &amp; Facilitair">Schoonmaak &amp; Facilitair</option>
                                            <option value="Sales">Sales</option>
                                            <option value="Sport, Recreatie &amp; Reizen">Sport, Recreatie &amp; Reizen
                                            </option>
                                            <option value="Techniek" selected="selected">Techniek</option>
                                            <option value="Zorg &amp; Welzijn">Zorg &amp; Welzijn</option>
                                            <option value="Groenvoorziening">Groenvoorziening</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="field-item type-dropdown education-dropdown half">
                                    <label class="form-item-label" for="vacancy_education">Opleidingsniveau
                                        <span>*</span></label>
                                    <div class="select-dropdown-wrapper">
                                        <select v-model="vacancyEducation.text" class="education-selector"
                                            name="vacancy_education">
                                            <option selected value="none">Selecteer opleidingsniveau</option>
                                            <option value="VMBO">VMBO</option>
                                            <option value="LBO">LBO</option>
                                            <option value="HAVO">HAVO</option>
                                            <option value="MBO">MBO</option>
                                            <option value="HBO">HBO</option>
                                            <option value="HBO">VWO</option>
                                            <option value="WO / Universitair">WO / Universitair</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="field-group has-child-below file-upload full">
                                    <div class="field-item">
                                        <label class="form-item-label" for="company_description">Vacature foto</label>
                                        <div class="radio-group">
                                            <div class="group-item">
                                                <input @click="vacancyAssetsEnabled = true" checked type="radio"
                                                    name="company_assets" value="yes" id="yes">
                                                <label for="yes">Ja, deze vacature heeft een eigen foto</label>
                                            </div>
                                            <div class="group-item">
                                                <input @click="vacancyAssetsEnabled = false" type="radio"
                                                    name="company_assets" value="no" id="no">
                                                <label for="no">Nee, ik gebruik de algemene foto</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field-item" v-show="vacancyAssetsEnabled">
                                        <div class="file-upload-wrapper dropdown-wrapper">
                                            <Dropzone :files-limit="1" accept-type="image/*,video/*"
                                                @update-files="updateAssetFiles">
                                            </Dropzone>
                                            <p v-if="vacancyAssets.error != ''" class="error-message">{{ vacancyAssets.error
                                            }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="buttons-wrapper">
                                    <button @click="prevStep" class="button alt">Vorige</button>
                                    <button @click="nextStep" class="button">Volgende</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>